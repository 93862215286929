/* Component: What You Get Module (Tabs) */

/* Import CSS for pagination styled like dots */
@import '../common/dots-pagination.module.css';

.foraSwiper swiper-slide {
  @apply h-auto;
}

.foraSwiper swiper-container::part(pagination) {
  margin-top: 17px;
}
