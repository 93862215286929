/* Component: What You Get Module (Tabs) */

/* Import CSS for pagination styled like dots */

/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.what-you-get-module-tabs_foraSwiper__jDqCl swiper-container::part(pagination) {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  position: static;
  display: flex;
  justify-content: center;
}

.what-you-get-module-tabs_foraSwiper__jDqCl swiper-container::part(bullet-active) {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 115, 99, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .what-you-get-module-tabs_foraSwiper__jDqCl swiper-container::part(bullet-active) {
    display: none;
  }
}

.what-you-get-module-tabs_foraSwiper__jDqCl swiper-container::part(bullet) {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 177, 164, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .what-you-get-module-tabs_foraSwiper__jDqCl swiper-container::part(bullet) {
    display: none;
  }
}

.what-you-get-module-tabs_foraSwiper__jDqCl swiper-slide {
  height: auto;
}

.what-you-get-module-tabs_foraSwiper__jDqCl swiper-container::part(pagination) {
  margin-top: 17px;
}

